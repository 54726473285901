.letter {
    margin: 0;
    padding: 0;
    /* font-size: xx-large; */
}

.default {
    color: black;
}

.correct {
    color: green;
}

.wrong {
    color: red;
}